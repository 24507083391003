import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe, Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  userSignupDetail: EventEmitter<any> = new EventEmitter<any>();
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public activeTabIndex = new BehaviorSubject<any>(0);
  activeTabIndex$ = this.activeTabIndex.asObservable();

  /** Doc event */
  public detectEventsForDocs = new BehaviorSubject<boolean>(false);
  detectEventsForDocs$ = this.detectEventsForDocs.asObservable();

  notify = new BehaviorSubject<any>(null);
  notify$ = this.notify.asObservable();

  sendDocEvent(data: any) {
    this.detectEventsForDocs.next(data);
  }

  sendTabIndex(data: any): void {
    this.activeTabIndex.next(data);
  }

  userData: any;
  private readonly userKey = 'PRAuser';

  constructor(private router: Router, private location: Location, private datePipe: DatePipe) { }

  // Mark for check
  public markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }

  /** Get user data */
  getUser(): any {
    const userJSON = localStorage.getItem(this.userKey);
    if (userJSON) {
      return JSON.parse(userJSON);
    }
    return null;
  }

  // Function to update and store data in local storage
  updateData(updatedData: any): void {
    const currentData = this.getUser() || {};
    const mergedData = { ...currentData, ...updatedData };
    localStorage.setItem(this.userKey, JSON.stringify(mergedData));
    // Emit the updated data to all subscribers
    this.userDataSubject.next(mergedData);
  }

  // Function to get user data as an Observable
  getUserData(): Observable<any> {
    return this.userDataSubject.asObservable();
  }

  /** Logou user */
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /** Check PA Auth token is available is not  */
  public checkIfUserIsAuthenticated(): boolean {
    // Check if the JWT token exists and is not expired
    const token = localStorage.getItem('PRAtoken');
    if (token) {
      // Here, you can use a JWT library (e.g., jwt-decode) to decode the token and check its expiration date
      // For simplicity, we assume that the token is valid if it exists.
      return true;
    }
    return false;
  }

  /** Get timezone */
  getTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // You can use timeZone as needed in your component or send it to an API.
  }

  // for making query params for api urls
  public formUrlParam(url: string, data: any): string {
    let queryString: string = '';
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
        if (Array.isArray(data[key])) {
          for (const value of data[key]) {
            if (value !== undefined && value !== null) {
              if (!queryString) {
                queryString = `?${key}%5B%5D=${encodeURIComponent(value)}`;
              } else {
                queryString += `&${key}%5B%5D=${encodeURIComponent(value)}`;
              }
            }
          }
        } else {
          if (!queryString) {
            queryString = `?${key}=${encodeURIComponent(data[key])}`;
          } else {
            queryString += `&${key}=${encodeURIComponent(data[key])}`;
          }
        }
      }
    }
    return url + queryString;
  }

  /** Date and time formate */
  formatDate(dateString: any, formatedType: string): any {
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000); // Convert to UTC
    return this.datePipe.transform(localDate, formatedType);
  }


  /** Get time */
  generateTimeOptions(startHour: number, endHour: number): { value: string }[] {
    const timeOptions: { value: string }[] = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      const time = hour % 12;
      const period = hour < 12 ? 'AM' : 'PM';
      const formattedHour = hour === 0 ? 12 : time;
      const value = `${hour.toString().padStart(2, '0')} (${formattedHour} ${period})`;
      timeOptions.push({ value });
    }
    return timeOptions;
  }

  /** Get minutes */
  generateMinuteOptions(): { value: string }[] {
    const minuteOptions: { value: string }[] = [];
    for (let minute = 0; minute < 60; minute++) {
      const value = minute.toString().padStart(2, '0') + ' mins';
      minuteOptions.push({ value });
    }
    return minuteOptions;
  }

  /** Formate selected date and time */
  formatSelectedDateAndTime(todayDate: Date, selectedTime: string, selectedMinutes: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const dayNames = [
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];

    // Get the components of the date and time
    const day = dayNames[todayDate?.getDay()];
    const month = monthNames[todayDate?.getMonth()];
    const year = todayDate?.getFullYear();
    const hours = parseInt(selectedTime, 10);
    const minutes = parseInt(selectedMinutes, 10);

    // Format the date and time
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12) || 12; // Convert to 12-hour format
    const formattedHoursWithLeadingZero = formattedHours.toString().padStart(2, '0');
    const formattedMinutesWithLeadingZero = minutes.toString().padStart(2, '0');
    const formattedDateTime = `${month ? month : ''} ${todayDate?.getDate() ? todayDate.getDate() : ''} ${month ? ',' : ''} ${year ? year : ''} ${day ? day : ''} ${formattedHoursWithLeadingZero}:${formattedMinutesWithLeadingZero} ${ampm}`;
    return formattedDateTime;
  }


  /** Get user type lable */
  getUserTypeLabel(data: any) {
    if (data) {
      switch (data.user_type) {
        case '2':
          return 'Tenant';
        case '3':
          return 'Medical provider';
        case '4':
          return 'Transportation provider';
        case '5':
          return 'Driver';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  /** Get status Name from Status Id */
  getStatusName(id: any) {
    if (id) {
      switch (id) {
        case '1':
          return 'New';
        case '2':
          return 'Verified';
        case '3':
          return 'Activated';
        case '4':
          return 'Deactivated';
        case '5':
          return 'Deleted';
        case '6':
          return 'Blocked';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  /** Get Return Full Name of Weekly Days */
  private dayNameMap: { [key: string]: string } = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    Th: 'Thursday',
    F: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  getFullDayName(abbreviatedName: string): string {
    const fullDayName = this.dayNameMap[abbreviatedName];
    return fullDayName || abbreviatedName; // Return the abbreviated name if no mapping is found.
  }

  /** Location back  */
  goBackScreen() {
    this.location.back();
  }

  /** Today Date Formate yyyy-mm-dd  */
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /** Convert Date and time in UTC to Local */
  formatDateTime(utcDate: string | null, utcTime: string | null) {
    if (!utcDate || !utcTime) {
      return '-';
    }
    const utcDateTime = utcDate + 'T' + utcTime + 'Z'; // Add 'Z' to indicate UTC time
    const localDateTime = new Date(utcDateTime);
    const localTime = this.datePipe.transform(localDateTime, 'medium');
    return localTime;
  }

}
